const toSerieFormat = (xData, yData, title, type) => {
  const out = {
    name: title,
    type: title === "Total" ? "line" : type,
    data: []
  };
  for (const i in yData) {
    out.data.push([xData[i], parseFloat(yData[i] || 0).toFixed(2)]); //if the yData is null (or nan) it is converted to 0
  }
  //console.log(out);
  return out;
};

const getData = (results, country) => {
  const type = results.graphType === "line" ? "line" : "area"; //FIXME remove this ? (the graphType is define in the series)
  const opts = {
    chart: {
      stacked: results.graphStacked === "false" ? false : true,
      events: {
        selection: (chart, e) => console.log(new Date(e.xaxis.min))
      },
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false
        },
        autoSelected: "zoom"
      },
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 10,
        animateGradually: {
          enabled: false,
          delay: 20
        },
        dynamicAnimation: {
          enabled: true,
          speed: 100
        }
      }
    },
    theme: {
      mode: "light",
      palette: "palette8"
    },
    dataLabels: {
      enabled: false
    },
    annotations: {
      position: "front",
      points: [
        {
          x: 2050,
          y: results.Name === "Total GHG emissions by sector" ? 5.563 : Infinity,
          marker: {
            size: 4,
            fillColor: "#ffffff",
            strokeColor: "#02754e",
            strokeWidth: 2,
            shape: "circle",
            radius: 1
          }
        },
        {
          x: 2050,
          y: results.Name === "Total GHG emissions by sector" ? 22.252 : Infinity, //FIXME trick to avoid showing the annotation on wrong graphs=> find another solution
          marker: {
            size: 4,
            fillColor: "#ffffff",
            strokeColor: "#027dab",
            strokeWidth: 2,
            shape: "circle",
            radius: 1
          }
        },
        {
          x: 2015,
          y: results.Name === "Total GHG emissions by sector" ? 113.52 : Infinity, //FIXME trick to avoid showing the annotation on wrong graphs=> find another solution
          marker: {
            size: 4,
            fillColor: "#ffffff",
            strokeColor: "#c73002",
            strokeWidth: 2,
            shape: "circle",
            radius: 1
          }
        }
      ],
      yaxis: [
        {
          y: results.Name === "Total GHG emissions by sector" ? 5.563 : Infinity, //FIXME trick to avoid showing the annotation on wrong graphs=> find another solution
          borderColor: "#02754e",
          strokeDashArray: 10,
          label: {
            borderColor: "#02754e",
            borderWidth: 1,
            position: "left",
            offsetX: 100,
            offsetY: -8,
            style: {
              color: "#fff",
              background: "#02754e"
            },
            text: "-95% target"
          }
        },
        {
          y: results.Name === "Total GHG emissions by sector" ? 22.252 : Infinity, //FIXME trick to avoid showing the annotation on wrong graphs=> find another solution
          borderColor: "#027dab",
          strokeDashArray: 10,
          label: {
            borderColor: "#027dab",
            borderWidth: 1,
            offsetX: 100,
            offsetY: -8,
            position: "left",
            style: {
              color: "#fff",
              background: "#027dab"
            },
            text: "-80% target"
          }
        },
        {
          y: results.Name === "Total GHG emissions by sector" ? 113.52 : Infinity, //FIXME trick to avoid showing the annotation on wrong graphs=> find another solution
          borderColor: "#c73002",
          strokeDashArray: 10,
          label: {
            borderColor: "#c73002",
            borderWidth: 1,
            offsetX: 110,
            offsetY: -8,
            position: "left",
            style: {
              color: "#fff",
              background: "#c73002"
            },
            text: "2015 emissions"
          }
        },
        {
          y: results.Name === "Total GHG emissions by sector" ? 143 : Infinity, //FIXME trick to avoid showing the annotation on wrong graphs=> find another solution
          borderColor: "#c7a602",
          strokeDashArray: 10,
          label: {
            borderColor: "#c7a602",
            borderWidth: 1,
            offsetX: 110,
            offsetY: -8,
            position: "left",
            style: {
              color: "#fff",
              background: "#c7a602"
            },
            text: "1990 emissions"
          }
        }
      ]
    },
    animations: {
      enabled: false
      // easing: "easeinout",
      // speed: 5,
      // animateGradually: {
      //  enabled: false,
      //  delay: 20
      //},
      //dynamicAnimation: {
      //  enabled: false,
      //  speed: 10
      //}
    },
    stroke: {
      curve: "straight",
      width: type === "area" ? 3 : 4
    },
    fill: {
      type: "solid"
      //gradient: {
      //  opacityFrom: 0.9,
      //  opacityTo: 1
      //}
    },
    colors: [
      function() {
       if (results.graphStacked === "true") { // if stacked are graph the first line is the total line so we use a red color for it
         return "#FF0000";
       } else {
         return "#8d2221";
       }
      },
      "#218C8D",
      "#6CCECB",
      "#F9E559",
      "#EF7126",
      "#8EDC9D",
      "#473E3F",
      "#FDB813",
      "#62C2CC",
      "#E4F6F8",
      "#EEF66C",
      "#D31D8C",
      "#EE88CD",
      "#BCDD11",
      "#A5F2F3",
      "#F1FAC0"
    ],
    legend: {
      position: "right",
      inverseOrder: true,
      showForSingleSeries: true,
      offsetX: 0,
      offsetY: 0,
      horizontalAlign: "center",
      width: 200
    },
    tooltip: {
      enabled: true,
      shared: true,
      inverseOrder: true,

      y: {
        formatter: function(val) {
          if (val > 1000000000) {
            return (
              (val / 1000000000)
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, " ") +
              " bn " +
              results.Unit
            );
          } else if (val > 1000000) {
            return (val / 1000000).toFixed(2) + " m " + results.Unit;
          } else if (val > 1000) {
            return (val / 1000).toFixed(2) + " k " + results.Unit;
          } else {
            if (val !== undefined) {
              if (val !== parseInt(val))
                val = val.toFixed(2) + " " + results.Unit;
            }
            return val;
          }
        }
      }
    },
    xaxis: {
      type: "numeric",
      tickAmount: (results.Name === "Air mortality" || results.Name === "Mortality cost" ) ? 9:10,
      labels: {
        offsetY: -3,
        formatter: function(val) {
          return val.toFixed();
        }
      }
    },
    yaxis: {
      max: function(max) {
        if (results.Name === "Total GHG emissions by sector") {
          return Math.min(max, 160); //FIXME : workaround to avoid wrong y-axis scale
        } else if (
          results.Name === "Final energy consumption by sector" ||
          results.Name === "Final energy consumption by vector"
        ) {
          return Math.min(max, 600); //FIXME : workaround to avoid wrong y-axis scale
        } else if (results.Name === "Diet evolution") {
          return Math.min(max, 12000000000000); //FIXME : workaround to avoid wrong y-axis scale
        } else if (results.Name === "Food waste evolution") {
          return Math.min(max, 4000000000000); //FIXME : workaround to avoid wrong y-axis scale
        } else if (
          results.Name === "GHG Emissions per energy vector in Buildings" ||
          results.Name === "GHG Emissions per end-use in Buildings"
        ) {
          return Math.min(max, 35); //FIXME : workaround to avoid wrong y-axis scale
        } else if (
          results.Name === "Energy demand per vector in Buildings" ||
          results.Name === "Energy demand per end-use in Buildings"
        ) {
          return Math.min(max, 180); //FIXME : workaround to avoid wrong y-axis scale
        } else if (results.Name === "Residential floor-area") {
          return Math.min(max, 800); //FIXME : workaround to avoid wrong y-axis scale
        } else if (results.Name === "Non-residential floor-area") {
          return Math.min(max, 300); //FIXME : workaround to avoid wrong y-axis scale
        } else if (results.Name === "Capital expenditure by building type") {
          return Math.min(max, 6000); //FIXME : workaround to avoid wrong y-axis scale
        } else if (results.Name === "Capital expenditure by appliances") {
          return Math.min(max, 4000); //FIXME : workaround to avoid wrong y-axis scale
        } else if (results.Name === "Energy costs by vector in Buildings") {
          return Math.min(max, 8000); //FIXME : workaround to avoid wrong y-axis scale
        } else if (
          results.Name === "GHG Emissions per subsector in transport" ||
          results.Name === "GHG Emissions per end-use in Transport"
        ) {
          return Math.min(max, 65); //FIXME : workaround to avoid wrong y-axis scale
        } else if (
          results.Name === "Energy demand per subsector in Transport" ||
          results.Name === "Energy demand by vector in Transport"
        ) {
          return Math.min(max, 150); //FIXME : workaround to avoid wrong y-axis scale
        } else if (results.Name === "Passenger Modal share") {
          return Math.min(max, 300000000000); //FIXME : workaround to avoid wrong y-axis scale
        } else if (results.Name === "Passenger Technology share") {
          return Math.min(max, 10000000); //FIXME : workaround to avoid wrong y-axis scale
        } else if (results.Name === "Freight Modal share") {
          return Math.min(max, 130000000000); //FIXME : workaround to avoid wrong y-axis scale
        } else if (results.Name === "Freight Technology share") {
          return Math.min(max, 70000); //FIXME : workaround to avoid wrong y-axis scale
        } else if (results.Name === "Capital and operations expenditure for vehicles and infrastrure") {
          return Math.min(max, 40000); //FIXME : workaround to avoid wrong y-axis scale
        } else if (results.Name === "Capital expenditures by technology (for passenger cars only)") {
          return Math.min(max, 20000); //FIXME : workaround to avoid wrong y-axis scale
        } else if (results.Name === "Fuel costs by vector in Transport") {
          return Math.min(max, 8000); //FIXME : workaround to avoid wrong y-axis scale
        } else if (
          results.Name === "Energy demand by vector in Industry" ||
          results.Name === "Energy demand by sector in Industry"
        ) {
          return Math.min(max, 250); //FIXME : workaround to avoid wrong y-axis scale
        } else if (
          results.Name === "Material production by material" ||
          results.Name === "Material production by technology"
        ) {
          return Math.min(max, 50000); //FIXME : workaround to avoid wrong y-axis scale
        } else if (
          results.Name === "Material demand by material" ||
          results.Name === "Material demand by product"
        ) {
          return Math.min(max, 70000000); //FIXME : workaround to avoid wrong y-axis scale
        } else if (results.Name === "Capital expenditure by material in Industry") {
          return Math.min(max, 2500); //FIXME : workaround to avoid wrong y-axis scale
        } else if (results.Name === "Fuel costs by vector in Industry") {
          return Math.min(max, 12000); //FIXME : workaround to avoid wrong y-axis scale
        } else if (
          results.Name === "Electricity production per source" ||
          results.Name === "Electricity demand per sector"
        ) {
          return Math.min(max, 200); //FIXME : workaround to avoid wrong y-axis scale
        } else if (results.Name === "Electricity production per RES") {
          return Math.min(max, 140); //FIXME : workaround to avoid wrong y-axis scale
        } else if (
          results.Name === "Fossil fuel demand - Oil" ||
          results.Name === "Fossil fuel production"
        ) {
          return Math.min(max, 800); //FIXME : workaround to avoid wrong y-axis scale
        } else if (results.Name === "Emissions from agriculture") {
          return Math.min(max, 15); //FIXME : workaround to avoid wrong y-axis scale
        } else if (results.Name === "Land allocation") {
          return Math.min(max, 3200000); //FIXME : workaround to avoid wrong y-axis scale
        } else if (results.Name === "Hydrogen and efuel demand") {
          return Math.min(max, 60); //FIXME : workaround to avoid wrong y-axis scale
        } else if (results.Name === "GHG emissions per industrial sector") {
          return Math.min(max, 50); //FIXME : workaround to avoid wrong y-axis scale
        } else {
          return max;
        }
      },
      min: function(min) {
        if (
          results.graphType === "line" 
        ) {
          return Math.min(0,min); //FIXME : workaround to avoid wrong y-axis scale
        }
        else {
          return min;
        }
      },
      seriesName: "Total",
      forceNiceScale: true,
      labels: {
        show: true,
        align: "right",
        minWidth: 0,
        maxWidth: 160,
        style: {
          color: undefined,
          fontSize: "12px",
          fontFamily: "Helvetica, Arial, sans-serif",
          cssClass: "apexcharts-yaxis-label"
        },
        formatter: function(val) {
          if (val > 1000000000) {
            return (
              (val / 1000000000)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " bn"
            );
          } else if (val > 1000000) {
            return val / 1000000 + " m";
          } else if (val > 1000) {
            return val / 1000 + " k";
          } else {
            if (val !== undefined) {
              if (val !== parseInt(val)) val = val.toFixed(2);
            }
            return val;
          }
        }
      },
      title: {
        text: results.Unit,
        rotate: 90,
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: "14px",
          fontFamily: "Open Sans, Arial, sans-serif",
          cssClass: "apexcharts-yaxis-title"
        }
      }
    }
  };
  const series = [];
  const xData = results.metrics[0].timeAxis;
  const tmp = [];
  var sum = (r, a) => r.map((b, i) => a[i] + b);

  for (const item of results.metrics) {
    const yData = item.data[country.value];
    if (item.id === "ind_emissions-GHG_CC[MtCO2e]" || item.id === "elc_emissions-CO2_CCS[MtCO2e]" || item.id === "ref_emissions-CO2_CCS[MtCO2e]"){
      tmp.push(yData.map(value => -value));
      series.push(toSerieFormat(xData, yData.map(value => -value), item.title, results.graphType));
    }
    else {
      tmp.push(yData);
      series.push(toSerieFormat(xData, yData, item.title, results.graphType));
    }
    
  }
  if (results.graphStacked === "true") {
    series.unshift(toSerieFormat(xData, tmp.reduce(sum), "Total", "line"));
  }
  return { opts, series, type };
};

const dataHelpers = {
  toSerieFormat,
  getData
};

export default dataHelpers;
