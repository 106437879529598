import React from "react";

import "./Modal.css";

//import Backdrop from "./Backdrop/Backdrop";

const Modal = props => {
  let message;
  let title;

  if (props.message === "busy") {
    message = "The server seems to be busy. Please try again later.";
    title = "Server Busy";
  } else {
    message =
      "Data for this scenario is not present in the database. Calculating a new patwhay may take up to 2 minutes.";
    title = "New Scenario";
  }

  return (
    <div className="">
      {/*<Backdrop show={props.show} clicked={props.modalClosed} />*/}
      <div
        className="modal"
        style={{
          transform: props.show ? "translateY(0)" : "translateY(-100vh)",
          opacity: props.show ? "1" : "0"
        }}
      >
        <h2 className="mb-2">{title}</h2>
        <p className="lead">{message}</p>
        <ul style={{ margin: "20px" }}>
          <li className="lead">
            Select <strong>"Start"</strong> to calculate the new scenario;
          </li>
          <li className="lead">Or move the position of the levers.</li>
        </ul>
        <div className="btn-div">
          <button className="calculate-new-btn" onClick={props.calcNew}>
            Start
          </button>
        </div>
      </div>
    </div>
  );
};
export default React.memo(Modal);
