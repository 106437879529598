import React from "react";

import "./Header.css";

const ecfLogo = process.env.PUBLIC_URL + "/static/img/beLogo.jpg";
const cwLogo = process.env.PUBLIC_URL + "/static/img/2050Logo.jpg";
const climactLogo = process.env.PUBLIC_URL + "/static/img/logo-climact.png";

const Header = () => {
  return (
    <header>
      <nav className="nav">
        <a
          href="https://www.belgium.be"
          target="_blank"
          rel="noopener noreferrer"
          className="nav__link"
        >
          <img src={ecfLogo} alt="Logo European Climate Foundation" />
        </a>
        <a
          href="https://climat.be/2050-en"
          target="_blank"
          rel="noopener noreferrer"
          className="nav__link"
        >
          <img src={cwLogo} alt="Logo Climate Works Foundation" />
        </a>
        <a href="/" className="nav__link nav__link--title">
          <h1>
            2050 Pathways Explorer Detailed - <span>Belgium</span>
          </h1>
        </a>
        <a
          href="http://www.climact.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="nav__link"
        >
          <img src={climactLogo} alt="Logo climact" />
        </a>
      </nav>
    </header>
  );
};

export default Header;
