import React from "react";

import "./Modal.css";

import Backdrop from "./Backdrop/Backdrop";

const Modal = props => {
  return (
    <div className="">
      <Backdrop show={props.show} clicked={props.modalClosed} />
      <div
        className="modal-lever"
        style={{
          transform: props.show ? "translateY(0)" : "translateY(-100vh)",
          opacity: props.show ? "1" : "0"
        }}
      >
        <div className="modal-header">
          <h2 className="mb-2"> </h2>
        </div>
        <div className="modal-content">
          <p> </p>
        </div>
      </div>
    </div>
  );
};
export default React.memo(Modal);
