export default async () => {
  const data = await fetch(
    "https://becalc-detailed.netzero2050.be/api/v1.0/graphs_details",
    {
      method: "GET"
    }
  );
  let result = await data.json();
  return result;
};
